.user-menu__toggler {
  border-radius: 50%;
  display: block;
  height: 2rem;
  margin: 0.3125rem;
  width: 2rem;
}

.user-menu__user-banner {
  padding: 0.25rem 0.75rem;
}

.user-menu .menu-toggler__toggler {
  border-width: 0;
}
