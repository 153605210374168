.hub {
  margin: auto;
  max-width: 50rem;
  overflow: hidden;
  position: relative;
  width: 90%;
}

.hub::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.hub .nexus {
  height: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  z-index: 100;
}

.hub .wheel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: inherit;
}

/* -------------------------------------------------------------------------- */

.nexus {
  background-color: inherit;
  border-radius: 100%;
  box-shadow: var(--shadow-omni-subtle);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 22%;
  width: 22%;
}

/* -------------------------------------------------------------------------- */

.wheel {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wheel .spoke {
  background-size: 6px 2px;
  color: #5e8766;
  padding: 0 1em;
  position: absolute;
  transition: transform 0.3s;
  top: calc(50% - 1.5em);
  width: 35%;
  z-index: 0;
}

.wheel .spoke:hover {
  z-index: 100;
}

.wheel .spoke.left.spoke-inbound::before {
  content: '';
  right: 15px;
  transform: scaleX(-1);
}

.wheel .spoke.right.spoke-inbound::before {
  content: '';
  left: 15px;
}

.wheel .spoke.left.spoke-outbound::after {
  content: '';
  right: calc(100% - 13em - 10px);
}

.wheel .spoke.right.spoke-outbound::after {
  content: '';
  left: calc(100% - 13em - 10px);
  transform: scaleX(-1);
}

.wheel .spoke.left .system .body {
  /* CHEAT */
  order: 1;
}

.wheel .spoke .arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.wheel .spoke .arrow .arrow__head {
  position: absolute;
  margin: auto;
  top: 0px;
  bottom: 0px;
}

.wheel .spoke .arrow .arrow__head--flip {
  transform: scaleX(-1);
  right: 0;
}
