.localization-strings .rt-tr {
  border-bottom: 1px solid var(--color-background);
}

.localization-strings .rt-tr a {
  text-decoration: none;
}

.edit-translation-dialog .default,
.localization-strings .default.language a,
.localization-strings .default.language {
  color: gray;
}

.edit-translation-dialog .missing,
.localization-strings .missing.language a,
.localization-strings .missing.language {
  color: lightsalmon;
}

.localization-strings .override.language a,
.localization-strings .override.language {
  color: black;
}

.localization-strings .rt-td.description,
.localization-strings .rt-td.description div,
.localization-strings .rt-td.description p {
  display: inline;
}

.edit-translation-dialog .material-dialog__dialog {
  top: 50%;
  width: 75%;
}

.edit-translation-dialog .description {
  margin-top: 1em;
}

.edit-translation-dialog textarea {
  margin-top: 1em;
  height: 15em;
  resize: none;
}

.tooltip {
  max-width: 30%;
}
.tooltip .localization-string-value {
  margin-top: 1em;
  margin-left: 1em;
}

.tooltip .localization-string-value {
  margin-top: 1em;
  margin-left: 1em;
}
