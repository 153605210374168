.dataset-inspector-viewer {
  display: flex;
  flex: 1 1 75%;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  overflow: hidden;
}

.dataset-inspector-viewer__entity {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.dataset-inspector-viewer__entity-body {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.dataset-inspector-viewer__entity-actions {
  flex: 0 0 auto;
  padding: 0 1rem 1rem 1rem;
  overflow: hidden;
}

.dataset-inspector-viewer__empty-entity {
  align-self: center;
  text-align: center;
  line-height: 2em;
  margin: 0 auto;
  color: var(--color-instruction-header);
  font-size: 1.2em;
}

.dataset-inspector-viewer__entity-header-copy-button {
  padding: 0;
  margin-left: 0.5rem;
  padding-right: 5px;
}
