.filter-selector__delete-filter {
  width: 1rem;
  height: 1rem;
  float: right;
  color: var(--color-danger);
}

.filter-selector__delete-filter svg {
  width: 1rem;
  height: 1rem;
}

.filter-selector__empty {
  padding: 0 0.5rem;
}
