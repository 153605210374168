.dataset-inspector-browser {
  display: flex;
  flex: 1 1 25%;
  flex-direction: column;
  max-width: 33.3%;
  min-width: 16rem;
  position: relative;
}

.dataset-inspector-browser__list-entities,
.dataset-inspector-browser__list-skeleton {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.dataset-inspector-browser__list-skeleton {
  mask-image: linear-gradient(180deg, black, transparent);
}

.dataset-inspector-browser__list-entities li {
  border-bottom: 1px solid var(--color-border);
  border-top: 1px solid transparent;
  cursor: pointer;
  list-style-type: none;
}

.dataset-inspector-browser__list-entities li:hover {
  background-color: var(--color-border);
}

.dataset-inspector-browser__list-entities .selected {
  background-color: var(--color-brand-lighting);
  border-color: var(--color-brand);
}

.dataset-inspector-browser__list-entities .previous {
  background-color: var(--color-background);
  border-color: var(--color-brand);
}

.dataset-inspector-browser__list-entity-summary {
  padding: 0.5rem;
  overflow: hidden;
}

.dataset-inspector-browser__list-entity-summary-details {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  color: black;
}

.dataset-inspector-browser__list-entity-summary-details > * {
  margin-right: 0.5em;
}

.dataset-inspector-browser__list-entity-summary-details > :last-child {
  margin-right: 0;
}

.dataset-inspector-browser__list-entity-status {
  font-size: 0.875em;
}
