.s-mock-input {
  position: relative;
  margin-top: 4px;
}
.s-mock-input__input {
  width: 100%;
  height: 3.5rem;
  padding-left: 0.75rem !important; /* TODO: Remove input styles in global.css, so we don't need !important */
  padding-right: 0.75rem !important; /* TODO: Remove input styles in global.css, so we don't need !important */
  border: 1px solid #d2d4d6;
  border-radius: 0.25rem;
  letter-spacing: 0.025rem;
  background-color: transparent;
}

.s-mock-input__label {
  position: absolute;
  left: 0.3125rem;
  top: -0.4375rem;
  font-size: 0.875rem;
  padding: 0 8px;
  color: #8e9397;
}
