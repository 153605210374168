.endpoint-node {
  background-color: #fff;
  box-shadow: var(--shadow-omni-subtle);
  display: inline-flex;
  height: 3em;

  width: 12rem;
  overflow: hidden;
  transition: all 0.2s;
}

.endpoint-node:hover {
  box-shadow: var(--shadow-omni-pronounced);
}

.endpoint-node .body {
  flex-grow: 1;
  color: initial;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  word-break: break-all;
}

.endpoint-node .info-pipe__icon {
  margin-right: 0.5em;
  vertical-align: middle;
}

.endpoint-node__link {
  display: flex;
}

.hub__wheel .spoke--inbound .endpoint-node__link {
  margin-right: 0.5rem;
}

.hub__wheel .spoke--outbound .endpoint-node__link {
  margin-left: 0.5rem;
  justify-content: flex-end;
}

.endpoint-node .title {
  font-size: 0.875em;
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.endpoint-node .action {
  background-color: #4c98be;
  border-width: 0;
  color: #fff;
  display: block;
  height: 4em;
}

.endpoint-node .action--disabled {
  background-color: grey;
}

.endpoint-node a.wrapper {
  display: inherit;
}

.endpoint-node button {
  font-family: var(--font-family-base);
  font-size: 13px;
}

.endpoint-node__link--error {
  color: var(--color-danger);
  display: inline-block;
  height: 1.25rem;
  padding: 0.125rem;
  vertical-align: text-bottom;
  width: 1.25rem;
}

.endpoint-node--error .body {
  box-shadow: inset 0px 0px 0px 1px var(--color-danger);
}

.endpoint-node--error .action {
  background-color: var(--color-danger);
}

.endpoint-node__links {
  display: flex;
  flex-direction: row;
}
