.bold-header {
  background-color: #00aeef;
  padding: 1.5rem 0;
  width: 100vw;
  color: white;
  background-image: url('../../images/visuals.png');
  background-size: 102%;
  background-repeat: no-repeat;
  background-position: center 10%;
}

.bold-header__text {
  font-size: 3rem;
  padding-left: 1.5rem;
}
