.config-list-item {
  margin: 0 0 1rem 0;
  display: flex;
}

.config-list-item:last-of-type {
  margin-bottom: 0;
  display: flex;
}

.config-list-item__config {
  flex: 1 1 50%;
  margin-right: 1rem;
}

.config-list-item__output {
  margin-left: 0rem;
  margin-right: 1rem;
  flex: 1 1 50%;
}
