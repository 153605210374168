.datahub-objects-palette__item {
  align-items: center;
  display: flex;
}

.datahub-objects-palette__item > svg {
  margin-right: 1rem;
  height: 2rem;
  width: 2rem;
}
