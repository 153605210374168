.pipe-output-section__header {
  color: #3d3935;
  font-family: var(--font-family-headings);
  font-size: 1.5rem;
  font-weight: 300;
}

.pipe-output-section {
  margin-right: 1rem;
  flex-direction: column;
  display: flex;
}

.pipe-output-section__content {
  display: flex;
  justify-content: flex-start;
}
