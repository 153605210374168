.jwt-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 30rem;
}

.jwt-modal__copy-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.jwt-modal__copy-text {
  flex: 1;
}

.col.gr-primary {
  width: 100%;
  max-width: max-content;
  min-width: 50%;
  flex: 1 1;
}

.gr-secondary {
  width: 100%;
  max-width: max-content;
  flex: 1 1;
}

#jwt-row {
  margin: 0;
  display: flex;
}

.heading-section.existing-jwts {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
