.gdpr-purpose-form {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.gdpr-purpose-form--modal {
  max-height: 70vh;
}

.gdpr-purpose-form__form {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 0 0.9375rem 0 0;
  overflow: auto;
}

.gdpr-purpose-page__expand-panel {
  padding: 1.5625rem;
}

.gdpr-purpose-form__header {
  padding: 0 0 0 0.625rem;
}

.gdpr-purpose-form__input {
  flex: 1;
  padding: 0.5625rem;
  min-width: 17rem;
}

.gdpr-purpose-form__row {
  display: flex;
  flex-wrap: wrap;
}

.gdpr-purpose-form__input textarea {
  resize: none;
}

.gdpr-data-type-form__additional-fields {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.gdpr-data-type-form__additional-fields-input {
  flex: 2;
}

.gdpr-data-type-form__additional-fields-button {
  margin: 0 0 0 0.625rem;
}

.gdpr-purpose-form__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.625rem;
}

.gdpr-purpose-form__delete,
.gdpr-purpose-form__cancel {
  margin: 0 0.625rem 0 0;
}

.gdpr-purpose-form__helper {
  flex: 1;
  padding: 0 0 0 1.4375rem;
  border-left: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
}

.gdpr-purpose-form__helper-text {
  padding: 4rem 0 0 0;
  line-height: 1.875rem;
}

.gdpr-data-type-form__sub-title {
  font-family: var(--font-family-headings);
  margin: 0 0 0.75rem 0.5625rem;
}
