.flow-config__side {
  margin-right: 1rem;
  overflow-y: auto;
  flex: initial;
}

.flow-config__item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flow-config__item__graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 20%;
}

.flow-config__item__icon {
  flex: initial;
}

.flow-config__item__line-top {
  flex: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgb(9, 9, 9) calc(50%),
    rgba(0, 0, 0, 0)
  );
}

.flow-config__item__line-top--first {
  opacity: 0;
}

.flow-config__item__line-bottom {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgb(9, 9, 9) calc(50%),
    rgba(0, 0, 0, 0)
  );
  flex: 1;
}

.flow-config__item__line-bottom--last {
  opacity: 0;
}

.flow-config__item__box {
  padding: 0.5rem;
  max-height: 10rem;
  overflow: auto;
}

.flow-config__item__box:hover {
  background-color: var(--color-border);
}

.flow-config__item__box-container {
  align-self: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  flex: 1 1 80%;
}

.flow-config__item--selected .flow-config__item__box {
  background-color: var(--color-brand-lighting);
  border-color: var(--color-brand);
}
