.entity-type-overview {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.entity-type-overview__key-value {
  margin-bottom: 1.25rem;
}

.entity-type-overview__properties-label {
  margin-bottom: 0.625rem;
}

.entity-type-overview__properties-list {
  height: 100%;
  margin-top: 0.625rem;
}

.entity-type-overview__flow {
  height: 100%;
}

.darkMode path.react-flow__minimap-mask {
  fill: #636e7b;
}

.darkMode .selected.selectable {
  border-color: #fff !important;
}