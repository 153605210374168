.navbar {
  background-color: #fff;
  border-left-width: 0;
  border-right: 1px solid var(--color-border);
  border-top-width: 0;
  position: relative;
  width: 16rem;
}

.navbar__toggler {
  display: block;
  background-color: var(--color-table-header);
  border-width: 0;
  color: #dbdcde;
  padding: 0 0.75em;
  text-align: right;
  width: 100%;
}

.navbar__toggler:focus {
  color: #fff;
  outline-width: 0;
}

.navbar__toggler:hover {
  color: #fff;
}

.navbar__toggler svg {
  display: block;
  height: 2em;
  margin-left: auto;
  padding: 0.25em 0;
  width: 2em;
}

.navbar__menu {
  padding-left: 0;
  margin: 0;
}

.navbar__menu-primary-item {
  background-color: var(--color-border);
  display: block;
  padding: 0.5em 0.625em;
}

.navbar__menu-item {
  display: block;
  color: #000;
}

.navbar__menu-item:hover {
  background-color: var(--color-brand-active);
}

.navbar__menu-item:active {
  background-color: var(--color-notification);
}

.navbar__menu-item:hover .navbar__menu-link {
  background-color: transparent;
  color: var(--color-background);
}

.navbar__menu-link {
  align-items: center;
  display: flex;
  color: var(--color-brand-contrast);
  line-height: 2;
  overflow: hidden;
  padding: 0.25rem 0.75rem;
  position: relative;
  text-decoration: none;
}

.navbar__menu-link svg {
  flex: 0 0 2rem;
  margin-right: 1.25em;
  max-height: 2rem;
  max-width: 2rem;
  width: 2rem;
  z-index: 200;
}

.navbar__menu-link--active {
  background-color: var(--color-brand);
  color: var(--color-background);
}

.navbar__menu-link--disabled {
  opacity: 0.33;
}

.navbar__menu-link--new::after {
  color: #c93c37;
  content: 'New';
  position: absolute;
  top: -10px;
  right: -18px;
  font-size: 80%;
}

.navbar__menu-group {
  display: block;
}

.navbar__menu-group > ul {
  padding: 0;
}

.navbar__menu-label {
  background-color: var(--color-background);
  height: 2.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 0.75rem;
}

.navbar__footer {
  padding: 10px 20px;
  margin-left: 0;
  transform-origin: top left;
}

.navbar__footer ul {
  line-height: 1.75;
  margin: 0 0 1rem 0;
  padding: 0;
}

.navbar__footer li {
  display: inline;
}

.navbar__footer li a {
  white-space: nowrap;
}

.navbar__footer li::before {
  content: ' \2022 '; /* bullet character */
}

.navbar__footer li:first-child::before {
  content: none;
}

.navbar__footer p {
  margin: 0;
}

/**
 * navbar--collapsed
 * The navbar; collapsed into a narrow vertical bar (icons only)
 */
.navbar--collapsed {
  width: 3.75rem;
}

.navbar--collapsed .navbar__toggler svg {
  margin-right: 1px;
}

.navbar--collapsed .navbar__menu-label {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar--collapsed .navbar__menu-item {
  white-space: nowrap;
}

.navbar--collapsed .navbar__footer {
  font-size: smaller;
  transform: rotate(-90deg) translateX(-16rem);
  margin-left: -13rem;
  padding-top: 14.5rem;
}
