.flow-dashboard {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.flow-dashboard__flow {
  height: 15.625rem;
  flex: 0 0 auto;
  position: absolute;
  z-index: 99;
  padding-bottom: 2.5rem;
  width: 100%;
  box-shadow: var('--shadow-subtle');
}

.flow-dashboard__flow .graph {
  height: 100%;
}

.flow-dashboard__flow .diagram {
  background-image: none;
}

.flow-dashboard__config {
  overflow: auto;
  margin-top: 15.5625rem;
}
