.button-group {
  display: inline-flex;
}

.button-group .button {
  border-radius: 0;
  border-right: 1px solid #d2d2d2;
  margin: 0;
}

.button-group button:first-child {
  border-top-left-radius: var(--radius-default);
  border-bottom-left-radius: var(--radius-default);
}

.button-group button:last-child {
  border-right: 0;
  border-top-right-radius: var(--radius-default);
  border-bottom-right-radius: var(--radius-default);
}
