.gdpr-config--databrowser-logo {
  width: 165px;
}

.color-pickers {
  display: flex;
}

.color-pickers > * {
  margin-right: 20px;
}
