.piehub-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

.outlined-svg-text {
  font-weight: bold;
  font-size: 120%;
  paint-order: stroke;
  stroke: #000000;
  stroke-width: 2px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
}
