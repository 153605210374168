.wizard {
  display: flex;
  flex-direction: column;
}

.wizard__title {
  font-family: var(--font-family-headings);
  font-size: 1.25rem;
}

.wizard__step {
  padding: 1.25rem 0;
}

.wizard__actions {
  display: flex;
  align-items: center;
}

.wizard__step-selector {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-right: 1.25rem;
  align-items: center;
}

.wizard__progress-bar {
  margin: 0 0.9375rem 0 0.9375rem;
  width: 100%;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 100%;
}

.wizard__error-counter {
  margin: 0 0.9375rem 0 0;
  flex-shrink: 0;
}

.wizard__error-counter--invalid {
  color: var(--color-danger);
}

.wizard__error-counter--valid {
  color: #008580;
}

.wizard__input-dropdown {
  width: 100%;
  position: relative;
}

.wizard__dropdown {
  position: absolute;
  width: 100%;
  list-style: none;
}
