/**
 * Expansion panel
 */

.expansion-panel {
  transition: margin var(--duration-major);
}

.expansion-panel__summary {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  display: flex;
  font-family: inherit;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
}
.expansion-panel__summary-text {
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.expansion-panel__summary:focus {
  outline: none;
}

.expansion-panel__toggle {
  flex: 0 0 auto;
  margin-left: auto;
  transition: transform var(--duration-minor);
  width: 1.5rem;
}

.expansion-panel__toggle > svg {
  display: block;
  height: 2rem;
  width: 2rem;
}

.expansion-panel_head {
  display: flex;
  align-items: center;
  padding: 0.1rem 0.5rem;
}

.expansion-panel_head > svg {
    position: relative;
    left: 5px;
}

.expansion-panel__body {
  transition: all var(--duration-major);
}

.expansion-panel__content {
  padding: 0 1rem 1rem 1rem;
}

.expansion-panel__content > :last-child {
  margin-bottom: 0;
}

.expansion-panel--expanded .expansion-panel__body {
  overflow: auto;
  visibility: visible;
}

.expansion-panel--expanded .expansion-panel__toggle {
  transform: rotate(180deg);
}

.expansion-panel--collapsed .expansion-panel__body {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
}

.expansion-panel--collapsed .expansion-panel__toggle {
  transform: rotate(0deg);
}

/**
 * Expansion panel list; to be applied to <ul> or <ol>
 */

.expansion-panel-list {
  margin: 0;
  padding: 0;
}

.expansion-panel-list > li {
  display: block;
}

.expansion-panel-list > li:not(:first-child) > .expansion-panel {
  margin-top: -1px;
}

.expansion-panel-list > li:not(:first-child) > .expansion-panel--expanded {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.expansion-panel-list > li:first-child > .expansion-panel--expanded {
  margin-bottom: 1rem;
  margin-top: 0;
}
