/**
 * header-button
 * A button for use in the app header
 */
.header-button {
  align-items: center;
  background-color: var(--color-app-header);
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  position: relative;
  width: calc(var(--size-app-header-height) + 1px);
}

.header-button.dark {
  background-color: var(--color-brand-contrast);
}

.header-button > a,
.header-button > button {
  display: block;
  padding: 0.3125rem;
  text-decoration: none;
}

.header-button > a > img,
.header-button > a > svg,
.header-button > button > img,
.header-button > button > svg {
  display: block;
  height: 2rem;
  position: relative;
  width: 2rem;
}

.header-button--toggler {
  box-shadow: 0 0 0 rgba(38, 35, 31, 0) inset;
  position: relative;
  transition: all var(--duration-minor);
  z-index: 999;
}

.header-button--open {
  background-color: var(--color-brand-contrast-pronounced);
  box-shadow: 0 2px 4px rgba(38, 35, 31, 1) inset;
}
