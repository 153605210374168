.user-feedback {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7px 0 7px;
  z-index: 999;
}

.user-feedback__input-button {
  background-color: #595652;
  border-radius: 3px;
  color: #aaaaaa;
  font-size: 13px;
  letter-spacing: 0.0187rem;
  padding: 3px 0.3125rem 0.1875rem 0.3125rem;
  width: 180px;
}

.user-feedback__text-area {
  background-color: #ffffff;
  border-radius: 0.1875rem;
  height: 7.875rem;
  font-size: 0.8125rem;
  letter-spacing: 0.0187rem;
  padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  position: absolute;
  top: 11px;
  width: 180px;
  box-shadow: 0 0 0 0.0625rem rgba(136, 152, 170, 0.1),
    0 0.9375rem 2.1875rem 0 rgba(49, 49, 93, 0.1), 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.08);
  transition: height 0.5s ease-in;
}

.user-feedback__text-area textarea {
  background: transparent;
  border: 0;
  resize: none;
  height: 4.6875rem;
  margin-bottom: 0.375rem;
}

.user-feedback__text-area textarea::placeholder {
  font-style: normal;
}

.user-feedback__text-area__buttons {
  display: flex;
  justify-content: space-between;
}
