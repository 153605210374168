.status-bar {
  position: absolute;
  bottom: 20px;
  left: 0;
}

.status-bar__item {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: var('--color-brand');
  color: white;
}

.status-bar__item--success {
  background-color: var('--color-success');
}
