.hub {
  color: #fff;
  position: relative;
  overflow: hidden;
}

.hub::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.hub__nexus {
  height: 160px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--shadow-omni-subtle);

  width: 160px;
  z-index: 100;
  color: black;
  background-color: inherit;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hub__wheel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
}

.hub__wheel .spoke {
  background-clip: content-box;
  background-image: repeating-linear-gradient(
    to right,
    currentColor 0%,
    currentColor 100%,
    rgba(255, 255, 255, 0) 33.3%,
    rgba(255, 255, 255, 0) 100%
  );
  background-position: 5px 50%;
  background-repeat: repeat-x;
  background-size: 6px 2px;
  padding: 0 1em;
  color: inherit;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 10rem);
  width: 20rem;
  z-index: 0;
}

.hub__wheel .spoke:hover {
  z-index: 1000;
}

.hub__wheel .spoke--dashed {
  background-image: repeating-linear-gradient(
    to right,
    currentColor 0%,
    currentColor 33.3%,
    rgba(255, 255, 255, 0) 33.3%,
    rgba(255, 255, 255, 0) 100%
  );
}

.hub__wheel .spoke--inbound .body {
  order: 1;
}

.hub__wheel .spoke--outbound {
  text-align: right;
}

.hub__wheel .arrow {
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  width: 10px;
  z-index: 1;
}

.hub__wheel .arrow--inbound {
  right: 15px;
  transform: scaleX(-1);
}

.hub__wheel .arrow--outbound {
  left: calc(100% - 13rem - 10px);
  transform: scaleX(-1);
}
