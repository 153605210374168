@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

@import './typography.css';
@import './layout.css';
@import './controls.css';
@import './components.css';
@import './material.css';
@import './loaders.css';

/* == Fundamentals ========================================================== */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  text-decoration-skip: ink;
  font-family: 'Barlow', sans-serif;
}

button,
input[type='button'] {
  border-radius: 0;
}

button:not(:disabled),
label {
  cursor: pointer;
}

select {
  width: 100%;
}

p {
  line-height: 1.5;
}

pre {
  margin: 0;
  padding: 1em;
}

textarea {
  width: 100%;
}

textarea:not([rows]) {
  height: 10em;
}

input[type='radio'],
input[type='checkbox'],
select {
  cursor: pointer;
}

/* The following ::range-* selectors rely on
 * https://github.com/jonathantneal/postcss-input-range
 */

input[type='range'] {
  appearance: none;
  min-height: 1.5rem;
  padding: 0;
  width: 100%;
}

input[type='range']::range-track {
  background: var(--color-button-lowlight);
  border-radius: 0;
  border-width: 0;
  box-shadow: var(--shadow-subtle);
  box-sizing: border-box;
  cursor: pointer;
  height: 0.5rem;
  margin: 0.75rem 0;
  transition: all var(--duration-minor);
  width: 100%;
}

input[type='range']::range-thumb {
  appearance: none;
  background: var(--color-button-highlight);
  border-radius: 0;
  border: 1px solid var(--color-button-inactive);
  box-shadow: var(--shadow-subtle), 0 0 0.075rem rgba(0, 0, 0, 0.5) inset;
  box-sizing: border-box;
  cursor: pointer;
  height: 1.25rem;
  transition: all var(--duration-minor);
  width: 1rem;
}

input[type='range']::-webkit-slider-thumb {
  margin-top: -0.375rem;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-moz-focus-outer {
  border-width: 0;
}

input[type='range']:focus::range-track {
  background: var(--color-brand-active);
}

input[type='range']:focus::range-thumb {
  background: var(--color-background);
  box-shadow: var(--shadow-subtle), 0 0 0.075rem var(--color-brand-active) inset;
  border-color: var(--color-brand);
}

input[type='range']:hover::range-track {
  background: var(--color-brand-active);
}

input[type='range']:hover::range-thumb {
  background: var(--color-background);
  box-shadow: var(--shadow-pronounced), 0 0 0.075rem rgba(0, 0, 0, 0.5) inset;
}

input[type='range']:focus:hover::range-thumb {
  box-shadow: var(--shadow-pronounced), 0 0 0.075rem var(--color-brand-active) inset;
  border-color: var(--color-brand);
}

/* ------------------------ */

button[disabled],
input[disabled],
select[disabled],
textarea[disabled] {
  color: var(--color-button-inactive);
  cursor: not-allowed;
}

/* input:not([type='radio']):not([type='checkbox']):not([type='range'])[disabled],
select[disabled],
textarea[disabled] {
  background-color: var(--color-background);
  border-style: dotted;
  color: var(--color-button-inactive);
} */

input:not([type='radio']):not([type='checkbox']):not([type='range']):invalid,
textarea:invalid {
  border-color: var(--color-danger-subtle);
  border-bottom-color: var(--color-danger);
}

kbd {
  background-color: var(--color-background);
  background-image: radial-gradient(
    ellipse at top,
    rgba(255, 255, 255, 0.65) 0%,
    rgba(0, 0, 0, 0.075) 100%
  );
  border: 1px solid var(--color-border);
  border-radius: 0.225em;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: var(--color-brand-contrast);
  display: inline-block;
  font-family: monospace;
  font-size: inherit;
  line-height: 1.5em;
  margin: 0 0.1em 1px 0.1em;
  min-width: 2em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 #fff;
}

code {
  background-color: rgba(0, 0, 0, 0.0625);
  padding: 0 0.25em;
}

a {
  color: var(--color-brand);
}

a:hover {
  color: var(--color-brand-active);
  text-decoration: underline;
  text-underline-position: under;
}

.table__data a:hover {
  text-underline-position: auto;
}

span.last-run:hover {
  text-decoration: underline;
}

::placeholder {
  font-style: italic;
  color: var(--color-button-inactive);
  opacity: 1;
}

/* == Utilities ============================================================= */

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

/* -- TODO: review this section --------------------------------------------- */

#reactrootdiv {
  height: 100% !important; /* TODO: remove important when not being spammed inline */
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

#busyIndicator {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--stack-order-notification);
}

#busyIndicatorShowCounter {
  display: none;
}

/* Table */
.table {
  overflow: auto;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.table__table {
  width: 100%;
}

.table__header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  z-index: 99;
}

.table__header--sticky {
  position: sticky;
  background-color: var(--color-background);
  top: 0;
  bottom: 0;
}
.table__header--sticky--dark {
  background-color: var(--color-background--dark);
}

.table__header-col {
  padding: 5px 5px;
  user-select: none;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table__header-type {
  color: gray;
  font-size: 0.625rem;
  margin-left: 0.3125rem;
}

.table__header-col--sorted-asc {
  box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.75);
}

.table__header-col--sorted-desc {
  box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.75);
}

.table__header-resizer {
  height: 100%;
  width: 16px;
  display: inline-block;
  position: absolute;
  right: -8px;
  z-index: 1000;
  top: 0;
}

.table__row--pinned {
  position: sticky;
  top: 32px;
  bottom: 0;
  background-color: wheat !important;
}

.table__row--pinned--dark {
  background-color: burlywood !important;
}

.table__row--selected {
  background-color: var(--color-brand) !important;
  border-color: var(--color-brand) !important;
  color: white;
}

.table__row--selected--dark {
  background-color: var(--color-brand) !important;
  border-color: var(--color-brand) !important;
  color: white;
}

.table__row--even {
  background: rgba(0, 0, 0, 0.03);
}

.table__data {
  padding: 5px 5px;
  white-space: nowrap;
  max-width: 93.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__icon svg {
  width: 1rem;
  height: 1rem;
  fill: black;
}

.fadeIn {
  animation: fadeInAnimation 0.7s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOutAnimation 0.7s linear;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
