.databrowser-config--errors-list {
  border-style: solid;
  border-width: 2px;
}

.databrowser-config--errors-list-warning {
  color: orange;
}

.databrowser-config--errors-list-error {
  color: #c93c37;
}

.databrowser-config--error {
  color: #c93c37;
}

.databrowser-config--error-critical {
  color: darkred;
}

.databrowser-config--error-error {
  color: #c93c37;
}

.databrowser-config--error-warning {
  color: orange;
}

.configsection {
  margin: 0;
  padding: 15px;
}

.configsection .yamldumplink {
  display: none;
}
.configsection h2 {
  margin-top: 20px;
}

.configsection h2 .section-name {
  font-weight: bold;
}

.configsection table {
  width: 100%;
}

.configsection table,
.configsection th,
.configsection td {
  border: 1px solid black;
}

.configsection th,
.configsection td {
  _font-size: 12px;
  word-wrap: break-word;
  text-align: left;
  vertical-align: top;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 10px;
}

.configsection td.rawconfig {
  _font-size: 8px;
}

.configsection td pre {
  margin-top: 0;
}

.configsection span.editable-click {
  cursor: pointer;
}

.configsection .configsection .yamldumplink {
  float: right;
}
