.command-palette {
  box-shadow: var(--shadow-pronounced);
  color: var(--color-content-background);
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 80vh;
  max-width: 50rem;
  overflow: hidden;
  position: fixed;
  top: 10vh;
  transform: translateX(-50%);
  width: 75vw;
  z-index: var(--stack-order-popover);
}

@supports (backdrop-filter: blur()) {
  .command-palette {
    backdrop-filter: blur(0.5rem);
    background-color: var(--color-brand-contrast-subtle);
  }
}

.command-palette__input {
  background-color: var(--color-background-semilight--dark);
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
}

.command-palette__searchtype-message {
  background-color: var(--color-brand);
  color: white;
  padding: 0.5rem;
  display: flex;
  line-height: 1.5;
  flex: 1;
  font-size: 80%;
  align-items: center;
  justify-content: center;
}

.command-palette__input-field {
  background-color: transparent;
  border-width: 0 !important;
  color: inherit !important;
  font-size: 1.5rem;
  padding: var(--padding-small) !important;
  width: 100%;
}

.command-palette__input-field::placeholder {
  font-style: normal;
  color: var(--color-content-background);
  opacity: 0.5;
}

.command-palette__results {
  background-color: var(--color-brand-contrast);
  border-top: 1px solid var(--color-border);
  flex: 1 1 auto;
  font-size: 1.25rem;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}

.command-palette__feedback,
.command-palette__hint {
  background-color: var(--color-brand-contrast);
  border-top: 1px solid var(--color-border);
  color: var(--color-notification-subtle);
  flex: 0 0 auto;
  font-size: 1.25rem;
  padding: var(--padding-small);
}

.command-palette__hint {
  font-size: 0.75rem;
  padding: var(--padding-tiny) var(--padding-tiny);
  text-align: right;
}

.command-palette__result {
  display: block;
  padding: var(--padding-small);
}

.command-palette__result:hover {
  background-color: var(--color-brand-active);
  cursor: pointer;
}

.command-palette__result--selected {
  background-color: var(--color-brand-active);
}
