.dtl-debugger {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dtl-debugger--top {
  flex-grow: 0;
  flex-basis: auto;
}

.dtl-debugger--bottom {
  display: flex;
  flex-grow: 3;
  position: relative;
}

.dtl-debugger__info {
  padding: 0.625rem;
}

.dtl-debugger__expression {
  margin-bottom: 0.625rem;
}

.dtl-debugger__arguments {
  height: 7rem;
  overflow-x: auto;
}

.dtl-debugger__arguments-list {
  list-style: none;
  padding: 0;
}

.dtl-debugger__arguments-list-item {
  margin-bottom: 0.625rem;
}

.dtl-debugger__arguments-list-links {
  cursor: pointer;
}

.dtl-debugger__actions {
  padding: 0.625rem 0;
}

.dtl-debugger__actions button {
  margin-right: 0.625rem;
}

.dtl-debugger__actions button:last-of-type {
  float: right;
  margin-right: 0;
}

.dtl-debugger__compare {
  display: flex;
  flex-grow: 1;
}
