.card-list__header {
  display: flex;
  flex: 0 0 auto;
  padding: 0.25rem 0;
}

.card-list__header-controls {
  flex: 0 1 auto;
}

.card-list__sort-selector {
  flex: 0 0 auto;
  margin: 0 0.25rem 0 auto;
}

.card-list__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem 0 0;
  padding: 0;
}

.card-list__list > li {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 1rem 1rem 0;
  width: calc(100% - 1rem);
  box-shadow: 1px 2px 8px 0px #00001144;
  border-radius: 4px;
}

.card-list__list > li:hover {
  box-shadow: 1px 2px 8px 2px #615e5eb3;
}

.card-list__list > li > .card {
  flex: 1 0 auto;
}

@media screen and (min-width: 50rem) {
  .card-list__list > li {
    width: calc(50% - 1rem);
  }
}

@media screen and (min-width: 75rem) {
  .card-list__list > li {
    width: calc(33.33% - 1rem);
  }
}

@media screen and (min-width: 105rem) {
  .card-list__list > li {
    width: calc(25% - 1rem);
  }
}

@media screen and (min-width: 160rem) {
  .card-list__list > li {
    width: calc(20% - 1rem);
  }
}

@media screen and (min-width: 188rem) {
  .card-list__list > li {
    width: calc(16.66% - 1rem);
  }
}
