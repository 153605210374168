.pipe-input {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.pipe-input__description {
  max-width: 45rem;
}

.pipe-input__content {
  display: flex;
  flex: 1 1 auto;
}

.pipe-input__content-section {
  width: 45rem;
}
