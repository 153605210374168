.comparePanel > .body {
  display: flex;
}

.comparePanel > .footer {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.comparePanel .left {
  float: left;
  width: 50%;
  padding: 5px;
  margin: 0px;
}

.comparePanel .single {
  width: 100%;
}

.comparePanel__timestamp {
  color: rgb(126, 126, 126);
}

.comparePanel .right {
  float: left;
  width: 40%;
  padding: 5px;
  margin: 0px;
}

.comparePanel .clearer {
  clear: both;
}
