.status-green {
  color: var(--color-status-green-light);
}

.status-yellow {
  color: var(--color-status-yellow-light);
}

.status-red {
  color: var(--color-status-red-light);
}

.status-grey {
  color: grey;
}

.status-black {
  color: balck;
}
