.subscriptions-selector {
  align-items: flex-end;
  color: var(--color-button-lowlight);
  display: flex;
  align-items: center;
}
.subscriptions-selector__current-sub {
  flex: 0 1 auto;
  font-family: var(--font-family-headings);
  margin-right: 0.5rem;
}

.subscriptions-selector__current-sub > .ellipsis {
  max-width: 20vw;
}

.subscriptions-selector__toggler {
  background: transparent;
  border: 1px solid transparent;
  padding: 0 0.5rem;
  vertical-align: super;
}

button.menu-toggler__toggler.subscriptions-selector__toggler {
  color: white;
  border: 1px solid transparent;
  padding-bottom: 6px;
}

button.menu-toggler__toggler.subscriptions-selector__toggler:hover {
  background: white;
  color: black;
  border: 1px solid transparent;
  padding-bottom: 6px;
}

.subscriptions-selector__icon {
  display: block;
  height: 1.25rem;
  transform: translateY(30%);
  width: 1.25rem;
}
