.actionpanel ul {
  margin: 0;
  padding: 0;
}

.actionpanel li {
  display: inline-block;
  margin-left: 0.5rem;
}

.actionpanel li:first-child {
  margin-left: 0;
}
