.system {
  background-color: #fff;
  box-shadow: var(--shadow-omni-subtle);
  display: inline-flex;
  height: 3em;
  overflow: hidden;
  transition: all 0.2s;
  width: 12em;
}

.system:hover {
  box-shadow: var(--shadow-omni-pronounced);
}

.system .body {
  flex-grow: 1;
  overflow: hidden;
  padding: 0.375em 1.125em;
}

.system .info-pipe__icon {
  margin-right: 0.5em;
  vertical-align: middle;
}

.system .title {
  font-size: 0.875em;
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.system .action {
  background-color: #4c98be;
  border-width: 0;
  color: #fff;
  display: block;
  height: 4em;
}

.system a.wrapper {
  display: inherit;
}

.system a:link,
.system a:visited {
  color: #5e8766;
}

.system button {
  font-family: var(--font-family-base);
  font-size: 13px;
}

.system__link--error {
  color: var(--color-danger);
  display: inline-block;
  height: 1.25rem;
  padding: 0.125rem;
  vertical-align: text-bottom;
  width: 1.25rem;
}

.system--error .body {
  box-shadow: inset 0px 0px 0px 1px var(--color-danger);
}

.system--error .action {
  background-color: var(--color-danger);
}
