.loading-panel {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.loading-panel__message {
  margin-bottom: 0;
}
