.options label {
  display: block;
}

.jsoneditor {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.jsoneditor__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.jsoneditor__gutter {
  flex: 0 0 60px;
  -webkit-flex: 0 0 60px;
  border-left: 1px solid #999999;
  border-right: 1px solid #999999;
  background-color: #efefef;
  overflow: hidden;
}

.jsoneditor__status > ul {
  margin: 0;
  padding: 0 0 0 1.25rem;
}

.jsoneditor--error .jsoneditor__content::before,
.jsoneditor--warning .jsoneditor__content::before {
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 30;
}

.jsoneditor--error .jsoneditor__content::before {
  border-color: var(--color-danger);
}

.jsoneditor--warning .jsoneditor__content::before {
  border-color: var(--color-warning);
}

.jsoneditor--error .jsoneditor__status,
.jsoneditor--warning .jsoneditor__status {
  border-top-style: dashed;
  border-top-width: 1px;
  color: #000;
  max-height: 20vh;
  overflow: auto;
  padding: 0.3rem 0.3rem 0.5rem 2rem;
  position: relative;
  word-break: break-word;
  font-size: 14px;
}

li.status-error {
  color: var(--color-danger);
  max-width: 95%;
}

li.status-warning {
  color: #464111;
  max-width: 95%;
}

.jsoneditor--error .jsoneditor__status {
  background-color: var(--color-danger-subtle);
  border-top-color: var(--color-danger);
}

.jsoneditor--warning .jsoneditor__status {
  background-color: var(--color-warning-subtle);
  border-top-color: var(--color-warning);
}

.jsoneditor--error .jsoneditor__status::before,
.jsoneditor--warning .jsoneditor__status::before {
  background-image: url('../../../images/icons/warning.svg');
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  height: 1rem;
  left: 0.65rem;
  position: absolute;
  top: 0.28rem;
  width: 1.125rem;
}

.ace-box {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.ace-box--compare {
  height: 100%;
}

/* .ace-box > div {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
} */

.ace-box > div {
  flex-grow: 1;
  position: relative;
}

.ace-box .ace_gutter {
  z-index: unset;
}

.ace_editor .ace_marker-layer .ace_selection {
  background: #019fdc54 !important;
}

/* ace find highlight */
.ace_editor .ace_marker-layer .ace_selected-word {
  background: #019fdc54 !important;
  border: 1px solid /*[[base-color]]*/ #4183C4 !important;
}

/* overriding ace autocomplete box width */
.ace_editor.ace_autocomplete {
  width: 24rem !important;
}

.linkHighlight {
  border-bottom: 2px solid #019fdc;
  position: absolute;
  cursor: pointer !important;
  pointer-events: auto;
}

.ace-dracula .ace_marker-layer .ace_bracket {
  border: 1px solid #f1fa8c !important;
}

.jsoneditor--readonly .ace_editor .ace_marker-layer .ace_bracket {
  display: none;
}
