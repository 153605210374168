.in-progress {
  height: 2rem;
  margin: 0 1rem;
  vertical-align: middle;
  width: 2rem;
}

.in-progress .p {
  animation: in-progress-fade var(--duration-cycle) infinite;
  fill: var(--color-brand);
  opacity: 0;
  stroke-width: 1%;
  stroke: transparent;
}

.in-progress--white .p {
  fill: white;
}

.in-progress .p01 {
  animation: none;
  opacity: 1;
}

.in-progress .p02 {
  animation-delay: calc(var(--duration-cycle-third) / 12);
}
.in-progress .p03 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 2);
}
.in-progress .p04 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 3);
}
.in-progress .p05 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 4);
}
.in-progress .p06 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 5);
}
.in-progress .p07 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 6);
}
.in-progress .p08 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 7);
}
.in-progress .p09 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 8);
}
.in-progress .p10 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 9);
}
.in-progress .p11 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 10);
}
.in-progress .p12 {
  animation-delay: calc(var(--duration-cycle-third) / 12 * 11);
}

.in-progress--small {
  height: 1rem;
  margin: 0 0.5rem;
  width: 1rem;
}

.in-progress--large {
  height: 4rem;
  margin: 0 2rem;
  width: 4rem;
}

.in-progress--inactive {
  visibility: hidden;
}

.in-progress--inactive .p {
  animation: none;
}

@keyframes in-progress-fade {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  34% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
