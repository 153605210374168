.price-calculator {
  margin: 0.5rem 0;
}

.price-calculator table {
  border-top: 1px solid var(--color-border);
  border-spacing: 0;
  width: 100%;
}

.price-calculator td:nth-child(2) {
  padding-left: 0.5rem;
  text-align: right;
  white-space: nowrap;
}

.price-calculator tfoot {
  font-weight: 700;
}

.price-calculator tfoot td {
  border-top: 1px solid var(--color-border);
}

.price-calculator td {
  padding: 0.5rem 0;
  line-height: 1;
  vertical-align: baseline;
}
