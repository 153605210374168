.network-acl__ip-list {
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.network-acl__ip-list > .item-list {
  margin: 0.5rem 0;
}

.network-acl__main-actions {
  padding: 0 1.25em;
}

/* New componets' styles */

.acl-item {
  justify-content: space-between;
}

.acl-list {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
}

.acl-list-header {
  margin-left: 10px;
}

.acl-description {
  margin-top: 15px;
}

.acl-add {
  margin-top: 25px;
}

.item-delete-action {
  margin-top: 12px;
  margin-bottom: auto;
  padding-bottom: 10px;
}

.item-delete-action > button {
  margin-top: -5px;
}

.acl-error {
  float: left;
  color: #c93c37;
  font-size: 0.8rem;
  margin-top: 15px;
  margin-left: 10px;
}
