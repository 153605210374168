.toaster {
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 2rem;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  z-index: var(--stack-order-notification);
}

.toast {
  background-color: var(--color-brand-contrast-subtle);
  border-radius: 0.25rem;
  border: 1px solid var(--color-button-lowlight);
  box-shadow: var(--shadow-pronounced);
  color: var(--color-background);
  display: flex;
  max-width: 50%;
  opacity: 0.9;
  padding: 0.5rem 0.75rem;
  pointer-events: auto;
  transition: bottom var(--duration-major) ease-in-out;
  bottom: 100px;
  position: relative;
}

.toast--appear {
  bottom: 0px;
}

.toast--dismiss {
  bottom: -100px;
}

@keyframes dismiss {
  from {
    opacity: 100
  }

  to {
    opacity: 0;
  }
}

.toast--notification {
  background-color: var(--color-notification-subtle);
  color: var(--color-notification);
}

.toast--success {
  background-color: var(--color-success-subtle);
  color: var(--color-success);
}

.toast--warning {
  background-color: var(--color-warning-subtle);
  color: var(--color-warning);
}

.toast__message {
  border-right: 1px solid currentColor;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
}

.toast__button {
  appearance: none;
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  padding: 0;
}

.toast__button>svg {
  display: block;
  height: 1.125em;
  width: 1.125em;
}