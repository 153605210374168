.system-dashboard {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
}

.system-dashboard__section {
  display: flex;
  width: 50%;
  padding: 0.3125rem;
}
