.login {
  display: flex;
  flex-direction: column;
}

.login__invite {
  margin: 1.1875rem 0 0 0;
}

.login__warning {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
}

.login__header {
  font-size: 1.25rem;
  font-family: var(--font-family-headings);
}

.login__methods {
  display: flex;
  align-items: center;
}

.login__third-party {
  flex: 1.5;
}

.login__or {
  flex: 0;
  margin: 0 1.25rem;
}

.login__username-password {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.login__username-password > * {
  margin-bottom: 0.8125rem;
}

.login__username-password > button {
  align-self: flex-end;
}

.login__action-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login__forgot-password {
  margin-right: 0.5rem;
  font-size: 0.875rem;
}
