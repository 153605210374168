.json-compare {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  top: 0px !important;
  left: 0px;

  /* these 3 lines are to prevents an unsightly scrolling bounce affect on Safari */
  height: 100%;
  width: 100%;
  overflow: auto;
}

.json-compare > div {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  position: relative;
}
.json-compare > div.acediff-gutter {
  flex: 0 0 60px;
  -webkit-flex: 0 0 60px;
  border-left: 1px solid #999999;
  border-right: 1px solid #999999;
  overflow: hidden;
}

.acediff-left-editor {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.acediff-right-editor {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.acediff-diff {
  background-color: rgba(216, 242, 255, 0.4);
  border-top: 1px solid #a2d7f2;
  border-bottom: 1px solid #a2d7f2;
  position: absolute;
  z-index: 4;
}
.acediff-diff.targetOnly {
  height: 0px !important;
  border-top: 1px solid #a2d7f2;
  border-bottom: 0px;
  position: absolute;
}
.acediff-connector {
  fill: rgba(216, 242, 255, 0.4);
  stroke: #a2d7f2;
}

.acediff-copy-left {
  float: right;
}
.acediff-copy-right,
.acediff-copy-left {
  position: relative;
}
.acediff-copy-right div {
  color: #000000;
  text-shadow: 1px 1px #ffffff;
  position: absolute;
  margin: 2px 3px;
  cursor: pointer;
}
.acediff-copy-right div:hover {
  color: #004ea0;
}
.acediff-copy-left div {
  color: #000000;
  text-shadow: 1px 1px #ffffff;
  position: absolute;
  right: 0px;
  margin: 2px 3px;
  cursor: pointer;
}
.acediff-copy-left div:hover {
  color: #c98100;
}
