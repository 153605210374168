.apologies {
  display: flex;
  bottom: 4rem;
  flex-direction: column-reverse;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: var(--stack-order-notification);
  align-items: flex-end;
}

.apologies > li {
  display: block;
  margin-bottom: 1rem;
}

.apologies .dismiss-all-button {
  margin-right: 0.5rem;
  background-color: var(--color-danger);
  z-index: var(--stack-order-notification);
  pointer-events: auto;
}

.apologies .dismiss-all-button:hover {
  background-color: var(--color-danger-highlight);
}

/* -------------------------------------------------------------------------- */

.apology {
  background: var(--color-danger-subtle);
  border-right-width: 0;
  box-shadow: var(--shadow-subtle);
  color: var(--color-danger);
  max-width: 50vw;
  padding: 1rem 1rem 0.5rem 3.25rem;
  pointer-events: auto;
  position: relative;
  transition: all var(--duration-minor);
}

.apology .apology__toggle {
  background-color: var(--color-danger);
  border-width: 0;
  bottom: 0;
  color: #fff;
  font-size: 0;
  left: 0;
  padding: 0.125rem;
  position: absolute;
  top: 0;
  width: 2rem;
}

.apology .apology__toggle:focus,
.apology .apology__toggle:hover {
  outline: none;
  background-color: var(--color-danger-highlight);
}

.apology .apology__toggle > svg {
  width: 100%;
}

.apology p {
  margin-top: 0; /* Hackish */
}

.collapsed p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* -------------------------------------------------------------------------- */

.apology--hidden {
  box-shadow: none;
  transform: translateX(calc(100% - 2rem));
}

.apology--hidden .apology__body {
  visibility: hidden;
}
