.gdpr-data-type-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.gdpr-data-type-page__table {
  flex: 1;
}

.gdpr-data-type-page__modal {
  width: 60rem;
  max-width: 93.75rem;
  overflow: hidden;
}

.gdpr-data-type-page__edit {
  padding: 0.9375rem;
  border-top: 0.0625rem solid #e5e5e5;
}

.gdpr-data-type-page__new-data-type {
  align-self: flex-end;
  margin-top: 1rem;
}
