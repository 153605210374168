.menu-toggler__toggler {
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.menu-toggler__toggler-default {
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
}

.menu-toggler--open > .menu-toggler__toggler,
.menu-toggler__toggler:focus,
.menu-toggler__toggler:hover {
  border-color: var(--color-border);
  outline: none;
}

.menu-toggler--open > .menu-toggler__toggler {
  box-shadow: var(--shadow-pronounced);
}

/* A simple overlay to make it look the toggler and menu are connected. This
  is stacked over the toggler button (and the label is stacked on top). Z-index
  places both above the menu popover. */
.menu-toggler--open > .menu-toggler__toggler::before {
  bottom: -3px;
  border-width: 0; /* Override in case the toggler is a button and has a focus rectangle */
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: calc(var(--stack-order-popover) + 10);
}

/* In case an ::after pseudoelement exists, put that on top of the overlay, too
  (e.g. the btn--dropdown down arrow) */
.menu-toggler--open > .menu-toggler__toggler::after {
  position: relative;
  z-index: calc(var(--stack-order-popover) + 10);
}

.menu-toggler__label {
  max-width: 10rem;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.menu-toggler--open > .menu-toggler__toggler > .menu-toggler__label {
  z-index: calc(var(--stack-order-popover) + 20);
}

.menu-toggler-popover {
  bottom: 1rem;
  margin-top: -2px;
  pointer-events: none;
  z-index: var(--stack-order-popover);
}

.menu-toggler-popover > .menu {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}
