.upload {
  width: 100%;
}

.upload__editor > div:last-child {
  height: 20rem;
}

.upload__editor-info {
  margin-bottom: 0.625rem;
}

.upload__file {
  margin-bottom: 0.625rem;
}

.upload__loader,
.upload__file,
.upload__success,
.upload__drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 0;
  height: 22.875rem;
  justify-content: center;
  border: 0.125rem dashed gray;
}

.upload__drop-msg {
  letter-spacing: 0.125rem;
  max-width: 92%;
  text-align: center;
}

.upload__drop-msg p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload__drop-msg--bold {
  font-weight: bold;
  letter-spacing: 0.125rem;
  margin-bottom: 0.3125rem;
  text-transform: uppercase;
}

.upload__icon {
  margin-bottom: 0.625rem;
}

.upload__icon svg {
  height: 4.375rem;
  width: 4.375rem;
}

.upload__actions {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.upload__action {
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
}

.upload__errors {
  margin-bottom: 0.3125rem;
  background-color: #f0d8dc;
  border: 0.0625rem solid #980808;
  padding: 0.3125rem;
  margin-bottom: 0.625rem;
}

.upload__highlight {
  border: 0.125rem dashed blue;
}

.upload__curl-copy {
  margin-top: 0.625rem;
  cursor: pointer;
}
