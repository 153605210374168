.download-entities__actions,
.download-entities__action {
  display: flex;
  flex-direction: row;
}

.download-entities > * {
  margin-bottom: 0.9375rem;
}

.download-entities__action {
  align-items: center;
  margin-right: 0.9375rem;
}

.download-entities__action > * {
  margin-right: 0.625rem;
  margin-top: 0px;
}

.download-entities__curl-copy,
.download-entities__url-copy {
  color: #0081c1;
  cursor: pointer;
}
