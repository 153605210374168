.index-preview {
  margin: 0.25rem 0;
}

.index-preview__group {
  padding: 0.3125rem 0 0.3125rem 0;
}

.index-preview__group p {
  margin: 0.3125rem 0;
}
