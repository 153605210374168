/**
 * key-value
 * A key-value listing
 */

.key-value {
  margin: 0;
}

.key-value .key-value__key {
  font-weight: bold;
  margin-top: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.key-value .key-value__key:first-child {
  margin-top: 0;
}

.key-value .key-value__val {
  margin-left: 0;
  margin-top: 0.33em;
  overflow: hidden;
  text-overflow: ellipsis;
}
