.facet {
  margin-right: 0.5rem;
  border-radius: var(--radius-default);
}

.facet input[type='checkbox'] {
  margin-right: 0.25rem;
}

.facet button {
  border-radius: var(--radius-default);
}

.facet--active button {
  color: #fff;
}

.menu-toggler--open button {
  border-radius: var(--radius-default) var(--radius-default) 0 0;
}

.facet_clear {
  margin-bottom: 10px;
}

.facet-clear_link {
  margin-left: 7px;
  font-size: 13px;
  cursor: pointer;
}
