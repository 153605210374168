.third-party-login {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.third-party-login__form {
  display: flex;
  margin-bottom: 0.8125rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.third-party-login__img {
  margin-right: 1rem;
}

.third-party-login__title {
}
