.toggle-switch {
  display: inline-flex;
  height: 1.5em;
  position: relative;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}

.toggle-switch__checkbox {
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
  left: 0;
  height: 20px;
}

.toggle-switch__off::after {
  border-radius: 0.25rem;
  box-shadow: var(--shadow-subtle-inset);
  content: '';
  display: inline-block;
  height: 1.125em;
  left: 0.5em;
  position: relative;
  transition: background-color var(--duration-minor);
  vertical-align: -0.25em;
  width: 2.5em;
}

.toggle-switch__on::before {
  background-color: var(--color-button-highlight);
  border-radius: 0.25rem;
  box-shadow: var(--shadow-pronounced), 0 0 0.0625rem rgba(0, 0, 0, 0.5) inset;
  content: '';
  display: block;
  height: 1.25em;
  top: -0.125em;
  position: absolute;
  transition: left var(--duration-minor);
  width: 1.25em;
}

.toggle-switch__checkbox:focus + .toggle-switch__on::before {
  border: 1px solid var(--color-brand-lighting);
}

.toggle-switch__off,
.toggle-switch__on {
  display: inline-block;
  line-height: 1;
  padding: 0 0.5em;
  position: relative;
}

.toggle-switch--is-off .toggle-switch__off::after {
  background-color: var(--color-button-lowlight);
}

.toggle-switch--is-off .toggle-switch__on::before {
  left: -2.625em;
}

.toggle-switch--is-on .toggle-switch__off::after {
  background-color: var(--color-brand);
}

.toggle-switch--is-on .toggle-switch__off--gray::after {
  background-color: var(--color-button-lowlight);
}

.toggle-switch--is-on .toggle-switch__on::before {
  left: -1.175em;
}

/**
 * Inline toggle switch; can be used within a natural language form.
 * @see https://www.jroehm.com/2014/01/ui-pattern-natural-language-form/
 */

.toggle-switch--inline .toggle-switch__off,
.toggle-switch--inline .toggle-switch__on {
  text-decoration: line-through;
}

.toggle-switch--inline.toggle-switch--is-off .toggle-switch__off,
.toggle-switch--inline.toggle-switch--is-on .toggle-switch__on {
  text-decoration: none;
}
