.progress-bar {
  width: 100%;
  height: 0.625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar__progress {
  height: 0.625rem;
  background-color: var(--color-brand);
  border-radius: 0.25rem;
}
