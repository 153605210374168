.form--datahub-config {
}

.form--datahub-config__new-config {
  display: flex;
  flex-direction: column;
}

.form--datahub-config__new-config .jsoneditor {
  height: 20rem;
}
