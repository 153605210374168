.secrets {
  padding-right: 1rem;
}

.secrets .secrets__action:hover {
  text-decoration: underline;
  cursor: pointer;
}

.secrets .secrets__action--danger {
  color: var(--color-danger);
}

.secrets .secrets__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secrets .secrets__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secrets .add-secret-form {
  padding: 1rem 0.5rem 1rem 0.5rem;
  width: 100%;
}

.secrets .update-secret-form {
  padding: 1rem;
  width: 100%;
}
