.list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.list-container * {
  outline: 0;
}

.list-table {
  max-height: 100%;
  display: flex;
}

.table__action-bar {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 auto;
  border-top: 1px solid #d6d6d6;
  align-items: baseline;
  z-index: 100;
}

.list-header-container {
  max-width: none !important;
}

.list-actions {
  display: flex;
  flex-direction: row-reverse;
}

.resizer {
  display: inline-block;
  background: blue;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
}

.filter-table__box {
  overflow: hidden;
}

.filter-table__box__header {
  height: 5em;
  padding: 1px 5px;
  position: relative;
  bottom: 5px;
}