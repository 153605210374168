.pipe-dashboard {
  display: grid;
  grid-template-columns: repeat(4, minmax(20%, 1fr));
  grid-template-rows: repeat(4, minmax(20%, 1fr));
  grid-row-gap: 0.625rem;
  grid-column-gap: 0.625rem;
  grid-auto-rows: 1fr;
  height: 100%;
}

.pipe-dashboard__section {
  flex: 1 1 auto;
  display: flex;
}

.pipe-dashboard__flow {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
}

.pipe-dashboard__execution {
  grid-column: 1 / span 2;
  grid-row: 4;
}

.pipe-dashboard__config {
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
}

.pipe-dashboard__output {
  grid-column: 3 / span 2;
  grid-row: 3 / span 2;
}

.pipe-dasbhoard__last-modified-by {
  font-size: 80%;
  padding: 0.3rem 0.3rem 0.2rem 0.2rem;
  border-top: 1px solid var('--color-border');
  text-align: right;
}
