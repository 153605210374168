.DetailView__Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.DetailView__List {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;
  flex: 1 1 20%;
  overflow: auto;
}

.DetailView__Item {
}

.DetailView__Content {
  flex: 3 1 80%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
