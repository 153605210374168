.app-header {
  background-color: var(--color-app-header);
  display: flex;
  height: var(--size-app-header-height);
  justify-content: space-between;
  align-items: center;
}

.app-header.dark {
  background-color: var(--color-brand-contrast);
}

/*
  1) Safari needs this or the image will be its natural size
*/
.app-header__logo {
  flex: 0 0 auto;
  height: 100%; /* 1 */
  margin: 0 1.5rem 0 1.5rem;
}

.app-header__logo-img {
  display: block;
  height: 100%;
  padding: 0.7rem 0 0.7rem 0;
  position: relative;
  right: 4px;
}

.app-header__utility-nav {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
}

.app-header__utility-nav > .subscriptions-selector {
  flex: 0 1 auto;
  margin-right: auto;
  align-items: center;
}
