@keyframes pulse-bg {
  /* Opacity is GPU-accelerated */
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-fg {
  /* Filter is GPU-accelerated */
  0% {
    filter: brightness(1) grayscale(0%);
  }
  75% {
    filter: brightness(1) grayscale(0%);
  }
  100% {
    filter: brightness(2) grayscale(100%);
  }
}

/* Re animation performance, see: https://www.smashingmagazine.com/2016/12/gpu-animation-doing-it-right/ */

.announcement-banner {
  display: flex;
  flex-direction: row-reverse;
}

.announcement-banner__toggler {
  box-shadow: none;
  z-index: 10;
}

.announcement-banner .announcement-banner__toggler::before {
  animation: pulse-bg var(--duration-cycle) ease-in infinite alternate;
  background-color: var(--color-danger);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.announcement-banner__toggler > a {
  animation: pulse-fg var(--duration-cycle) ease-in infinite alternate;
  color: var(--color-brand);
}

.announcement-banner--open .announcement-banner__toggler {
  background-color: var(--color-danger);
  border-left-width: 0;
  position: relative;
  width: var(--size-app-header-height);
}

.announcement-banner--open .announcement-banner__toggler::before {
  animation: none;
  content: '';
}

.announcement-banner--open .announcement-banner__toggler > a {
  animation: none;
  color: var(--color-danger-subtle);
}

.announcement-banner__announcements {
  color: var(--color-danger-subtle);
  font-size: 0.875rem;
  margin-left: 0.5rem;
  overflow: visible;
  transform: translateX(100%);
  transition: transform var(--duration-major), visibility var(--duration-major), z-index 0s 0s;
  visibility: hidden;
  z-index: 0;
}

.announcement-banner__announcements > ul {
  max-height: 42px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transition: max-height 0s 0s;
}

.announcement-banner--open .announcement-banner__announcements {
  transform: translateX(0);
  transition: transform var(--duration-major), visibility var(--duration-major),
    z-index 0s var(--duration-major);
  visibility: visible;
  z-index: var(--stack-order-popover);
}

.announcement-banner--open .announcement-banner__announcements > ul {
  max-height: 25vh;
  overflow: auto;
  transition: max-height var(--duration-major) var(--duration-major);
}

.announcement-banner__announcements > ul > li {
  background-color: var(--color-danger);
  display: block;
  min-height: var(--size-app-header-height);
  padding: 0.8125rem 1rem 0.8125rem 2rem;
}

.announcement-banner__announcements > ul > li::before {
  content: '\25B8';
  display: inline-block;
  margin-left: -1em;
  width: 1em;
}
