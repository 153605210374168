.facet-list {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
}

.facet-list__separator {
  width: 0.0625rem;
  background-color: #dbdcde;
  height: 2rem;
  margin: 0 0.625rem 0 0.625rem;
}

.facet-list__filter-selector {
  margin: 0 0 0 0.625rem;
}
