.generate-schema-panel {
  position: relative;
}

.generate-schema-panel__caution-message {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6e58d;
}

.generate-schema-panel__sample-size {
  padding: 5px;
  background-color: #dbdcde;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  right: 0;
  border-radius: 0 0 0 5px;
  top: 25px;
}

.generate-schema-panel__form {
  display: flex;
}

.generate-schema-panel__form input {
  width: 110px;
  margin-right: 5px;
}
