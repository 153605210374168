.log-list {
  max-width: 100%;
}

.log-list__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0.5rem;
  font-family: var(--font-family-headings);
  font-size: 1.125rem;
  font-weight: 300;
  height: 2.375rem;
}

.log-list__item {
  display: flex;
  align-items: center;
  padding: 0.8125rem;
  margin-top: -0.0625rem;
  justify-content: space-between;
}

.log-list__item-id {
  font-family: var(--font-family-base);
  font-size: 1.125rem;
  font-weight: 300;
}

.log-list__item-size {
  font-size: 0.8125rem;
  margin-left: 0.625rem;
  flex: 1 0 auto;
}

.log-list__item-actions {
  display: flex;
  margin-left: 20px;
  flex: 1 0 auto;
  width: 14.75rem;
  justify-content: flex-end;
}

.log-list__item-action {
  margin-left: 10px;
}

.log-list__item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  word-break: break-all;
}
