/* FONT FAMILY */
.material-display-4,
.material-display-3,
.material-display-2,
.material-display-1,
.material-headline,
.material-title,
.material-subheading,
.material-body-2,
.material-body-1,
.material-caption,
.material-buttton {
  font-family: var(--font-family-base);
}

/* FONT WEIGHT / FONT SIZE */

.material-display-4 {
  font-weight: 300;
  font-size: 112px;
}

.material-display-3 {
  font-size: 56px;
  line-height: 40px;
}

.material-display-2 {
  font-size: 45px;
  line-height: 48px;
}

.material-display-1 {
  font-size: 34px;
  line-height: 32px;
}

.material-headline {
  font-size: 24px;
  line-height: 32px;
}

.material-title {
  font-size: 21px;
}

.material-subheading {
  font-size: 16px;
  line-height: 28px;
}

.material-body-2 {
  font-size: 14px;
  line-height: 24px;
}

.material-body-1 {
  font-size: 14px;
  line-height: 20px;
}

.material-caption {
  font-size: 12px;
}

.material-buttton {
  font-size: 14px;
  text-transform: uppercase;
}

.material-display-3,
.material-display-2,
.material-display-1,
.material-headline,
.material-subheading,
.material-body-1,
.material-caption,
.material-buttton {
  font-weight: 400;
}

.material-title,
.material-body-2 {
  font-weight: 500;
}

/* FONT COLOR */

.material-display-4,
.material-display-3,
.material-display-2,
.material-display-1,
.material-caption {
  color: rgba(0, 0, 0, 0.54);
}

.material-headline,
.material-title,
.material-subheading,
.material-body-2,
.material-body-1,
.material-buttton {
  color: rgba(0, 0, 0, 0.87);
}
