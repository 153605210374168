.dataset-inspector-message {
  display: flex;
  border-width: 0 1px 1px 1px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.dataset-inspector-message__message {
  display: flex;
  align-items: center;
}

.dataset-inspector-message__text {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin: 0px 8px 0 5px;
}

.dataset-inspector-message__icon {
  height: 1rem;
  width: 1rem;
}

.dataset-inspector-message__actions {
  display: flex;
  align-items: center;
}

.dataset-inspector-message__custom-action {
  margin-right: 0.625rem;
}
