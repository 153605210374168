.action-bar {
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 1rem;
  transform-style: preserve-3d;
  transition: background-color var(--duration-major);
  flex: 1;
}

.action-bar__status-icon {
  background-size: cover;
  height: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
}

.action-bar__short-message {
  margin-right: 0.5rem;
}

.action-bar__message {
  margin-right: 0.5rem;
}

.action-bar__message-toggler {
  background-color: transparent;
  background-image: url('../../images/icons/expand.svg');
  background-size: 2rem 2rem;
  border-width: 0;
  height: 2rem;
  width: 2rem;
}

.action-bar__message-content {
  bottom: -100%;
  box-shadow: var(--shadow-omni-subtle);
  max-height: 50vh;
  max-width: 50%;
  overflow: auto;
  padding: 0.5rem;
  position: absolute;
  transform: translateZ(-1px);
  transition: visibility 0s linear var(--duration-major), bottom var(--duration-major);
  visibility: hidden;
}

.action-bar__actions {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}

.action-bar__actions > * {
  margin-left: 0.5rem;
}

.action-bar__actions > :first-child {
  margin-left: 0;
}

/* -- State: showing message ------------------------------------------------ */

.action-bar--show-message .action-bar__message-content {
  bottom: 100%;
  transition-delay: 0s;
  visibility: visible;
}

.action-bar--show-message .action-bar__message-toggler {
  background-image: url('../../images/icons/minimize.svg');
}

/* -- State: default status ------------------------------------------------- */

/*.action-bar--status-default,
.action-bar--status-default .action-bar__message-content {
  background-color: #DDD;
}*/

/* -- State: waiting status ------------------------------------------------- */

.action-bar--status-waiting,
.action-bar--status-waiting .action-bar__message-content {
  background-color: #c3e8ff;
}

.action-bar--status-waiting-dark,
.action-bar--status-waiting-dark .action-bar__message-content {
  background-color: var(--color-brand-lighting);
}

.action-bar--status-waiting .in-progress {
  margin: 0;
}

/* -- State: error status --------------------------------------------------- */

.action-bar--status-error,
.action-bar--status-error .action-bar__message-content {
  background-color: #ffdbdc;
}

.action-bar--status-error-dark,
.action-bar--status-error-dark .action-bar__message-content {
  background-color: rgb(155, 37, 57);
}

.action-bar--status-error-dark .action-bar__actions,
.action-bar--status-error-dark .action-bar__short-message {
  color: white;
}

.action-bar--status-error-dark .action-bar__status-icon,
.action-bar--status-error .action-bar__status-icon {
  background-image: url('../../images/icons/warning.svg');
}
