.feedback {
  margin: 0;
  overflow: hidden;
  padding: 0.5rem;
  text-overflow: ellipsis;
  display: inline-block;
}

.feedback--error {
  background-color: var(--color-danger-subtle);
  color: var(--color-danger);
}

.feedback--notification {
  background-color: var(--color-notification-subtle);
  color: var(--color-notification);
}

.feedback--success {
  background-color: var(--color-success-subtle);
  color: var(--color-success);
}

.feedback--warning {
  background-color: var(--color-warning-subtle);
  color: var(--color-warning);
}
