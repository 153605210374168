.acl {
  border-collapse: collapse;
  width: 100%;
}

.acl th {
  padding: 0 0.5rem 0.5rem 0.5rem;
  text-align: left;
}

.acl th.ace-type,
.acl th.principals {
  vertical-align: bottom;
}

.acl th.action {
  padding: 9em 0 0 0;
  width: 3rem;
  white-space: nowrap;
}

.acl th.action > div {
  transform-origin: bottom left;
  transform: translate(49px) rotate(315deg); /* IE 11 doesn't support calc() in translate() */
  transform: translate(calc(3rem + 1px)) rotate(315deg);
  width: 3em;
}
.acl th.action > div > span {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.acl tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.acl tr.ace-type-allow,
.acl tr.ace-type-allow_all {
  background-color: #66b6b3;
}

.acl tr.ace-type-deny,
.acl tr.ace-type-deny_all {
  background-color: #c44058b8;
}

.acl--dark tr.ace-type-allow,
.acl--dark tr.ace-type-allow_all {
  background-color: #008580;
}

.acl--dark tr.ace-type-deny,
.acl--dark tr.ace-type-deny_all {
  background-color: rgb(155, 37, 57);
}

/*
 * Center-align the checkboxes, left-align everything else
 */
.acl td {
  padding: 0.75rem 0.5rem;
  text-align: left;
  vertical-align: center;
}

.acl td.action-buttons {
  white-space: nowrap;
}

.acl td.principal {
  font-size: 0.85em;
  padding: 0.5rem;
}

.acl td.principal .MuiFormControl-root.MuiTextField-root {
  min-width: 230px;
}

.acl td.action {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem 0.5rem;
  text-align: center;
}

.acl th.filler,
.acl td.filler {
  width: 9rem;
}

.acl-editor .acl__action-bar {
  padding: 0.5rem 0;
}
