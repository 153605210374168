.not-found {
  background-image: url('../../images/pipes/confused.svg');
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 50% 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 50vh;
  justify-content: center;
  margin: 0 auto;
  max-width: 60rem;
  padding: 0 1rem;
  width: 100%;
}

.not-found > * {
  width: 45%;
}

.not-found p {
  line-height: 1.4;
}
