.data-view-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-view-table__header--copied {
  font-weight: bold;
}

.data-view-table__header-copy,
.data-view-table__header-delete {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.3125rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.data-view-table__header-copy {
  color: var(--color-success);
}

.data-view-table__header-delete {
  color: var(--color-danger-highlight);
}

.data-view-table__header-delete svg {
  width: 1rem;
  height: 1rem;
}

.data-view-table__header-name {
  display: flex;
  align-items: center;
}

.data-view-table__error {
  display: flex;
  flex-direction: row;
}

.data-view-table__error svg {
  height: 1rem;
  width: 1rem;
  color: var(--color-danger-highlight);
}

.data-view-table__error-text {
  margin-left: 10px;
}

.data-view-table__new {
  background-color: lightgreen;
}

.data-view-table__new--dark {
  background-color: rgb(14, 100, 14);
}

.data-view-table__edited--dark {
  background-color: rgb(133, 133, 48);
}
