.pipe-actions-menu {
  display: block;
  height: 1.5rem;
  transform: translateY(17.5%);
  width: 1.5rem;
}

.pipe-actions-menu__toggler {
  background: transparent;
  border: 1px solid transparent;
  margin-bottom: -0.25rem;
  padding: 0 0.5rem;
}
