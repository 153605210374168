.insights__highlight {
  font-weight: bold;
}

.insights__highlight--enabled {
  color: green;
}

.insights__highlight--disabled {
  color: #c93c37;
}

.insights__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.insights__empty-state p {
  max-width: 25rem;
}

.insights__disable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insights .empty-state__container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
