.dataset-inspector-filter {
  overflow: visible;
}

.dataset-inspector-filter__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: visible;
}

.dataset-inspector-filter__filter {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dataset-inspector-filter__search {
  align-items: center;
  display: flex;
  min-width: 16.25rem;
  margin-right: 1.875rem;
}

.dataset-inspector-filter__find,
.dataset-inspector-filter__jump {
  display: flex;
  align-items: center;
  margin-right: 15px;
  flex: 1;
  flex-wrap: wrap;
}

.dataset-inspector-filter__jump {
  max-width: 500px;
}

.dataset-inspector-filter__control {
  display: flex;
  align-items: center;
}

.dataset-inspector-filter__jump .dataset-inspector-filter__control {
  min-width: 276px;
}

.dataset-inspector-filter__control-label {
  font-weight: bold;
  margin-right: 0.5rem;
}

.dataset-inspector-filter__search-by,
.dataset-inspector-filter__jump-by {
  display: flex;
}

.dataset-inspector-filter__search-field,
.dataset-inspector-filter__jump-field {
  display: flex;
  height: 2.625rem;
  overflow: visible;
  display: flex;
  align-items: center;
  flex: 1;
}

.dataset-inspector-filter__search-field {
  min-width: 317px;
}

.dataset-inspector-filter__jump-field {
  min-width: 223px;
}

.dataset-inspector-filter__search-field-info {
  color: var(--color-notification);
}

.dataset-inspector-filter__jump-field-timestamp {
  flex: 1;
}

.dataset-inspector-filter__radio-button-group {
  min-width: 260px;
}

.dataset-inspector-filter__action {
  margin-right: 0.625rem;
}

.dataset-inspector-filter__toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}

.dataset-inspector-filter__browse {
  align-items: center;
  display: flex;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.dataset-inspectpr-filter__subset {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.dataset-inspector-filter__browse-by {
  margin-right: 0.5rem;
  display: flex;
}

.dataset-inspector-filter__checkbox input {
  margin-right: 0.375rem;
}

.dataset-inspector-filter__selection  {
  min-width: 16.25rem;
}

.dataset-inspector-filter__jump-field__button,
.dataset-inspect-filter__search-field__button {
  height: 41px;
  min-width: 3rem;
  justify-content: center;
}

.dataset-inspector-filter__search-field-input--highlighted {
  box-shadow: 0 0 0 2px #089c40 inset;
}

.dataset-inspector-filter__radio-button-group {
  margin-right: 0.625rem;
  display: flex;
  align-items: baseline;
}

.dataset-inspector-filter__radio-button-label {
  margin-left: 0.3125rem;
}
