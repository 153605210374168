.vpn-tab-primary {
  width: 60%;
  min-width: 25em;
}

.vpn-tab-secondary {
  width: 40%;
}

button.add-vpn-connection {
  margin-top: 10px;
  margin-bottom: -10px;
}

.delete-vpn-connection {
  float: right;
}
