.secrets .data-table__footer-controls {
  flex-grow: 1;
}

.secrets__delete {
  opacity: 0;
  transition: opacity var(--duration-minor);
}

.secrets tr:hover .secrets__delete,
.secrets__delete:focus {
  opacity: 1;
  background-color: #fff;
  padding: 3px;
}
