/**
 * focus-panel
 * An important panel, to draw user focus
 */
.focus-panel {
  background: #fff;
  border-top: 0.25rem solid #00aeef;
  box-shadow: var(--shadow-subtle);
  padding: 2rem 2rem 1.5rem 2rem;
  margin: auto;
  max-width: 90vw;
  width: 30rem;
}

@media screen and (min-width: 64rem) {
  .focus-panel {
    width: 60vw;
    max-width: 40rem;
  }
}
