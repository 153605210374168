.last-run-container {
  position: relative;
  word-break: break-word;
}

.last-run {
  display: inline-block;
  min-width: 100%;
}

.last-run__error {
  display: inline-block;
  height: 1.25rem;
  padding: 0.125rem;
  vertical-align: text-bottom;
  width: 1.25rem;
  margin-right: 3px;
  position: relative;
  top: 1px;
}

.last-run__more {
  padding: 0 0.5rem;
  opacity: 0;
  padding: 0 1rem;
  position: absolute;
  right: 0;
  background-color: #fff;
}

.last-run:hover .last-run__more,
.last-run__more:focus {
  opacity: 1;
}