.material-dialog__dialog {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 600;
  min-width: 280px;
}

.material-dialog__dialog-header {
  padding: 24px 24px 20px 24px;
}

.material-dialog__dialog-content {
  padding: 0 24px 24px 24px;
}

.material-dialog__overlay {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
