.pipe-status {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.pipe-status__item {
  margin-bottom: 0.625rem;
  margin-right: 0.9375rem;
  flex: 0 0 auto;
  max-width: 24rem;
}

.pipe-status__key {
  font-weight: bold;
  color: var(--color-brand-contrast-pronounced);
}

.pipe-status__value {
  word-break: break-all;
  overflow: auto;
  max-height: 3rem;
  height: 100%;
}

.status-green {
  color: var(--color-status-green-light);
}

.status-red {
  color: var(--color-status-red-light);
}
