.shareable-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.shareable-links > .ReactTable {
  width: 100%;
}

.shareable-links > .ReactTable .rt-noData {
  top: 70%;
}
.shareable-links__link {
  justify-content: center;
  color: var(--color-brand);
  cursor: pointer;
  text-decoration: underline;
}

.shareable-links__link--danger {
  color: var(--color-danger);
}
.shareable-links__links {
  padding: 0.9375rem;
}

.shareable-links__exp--active {
  color: black;
}

.shareable-links__exp--expired {
  color: var(--color-danger);
}

.shareable-links__form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shareable-links__exp-input {
  margin-right: 1rem;
}

.shareable-links__create {
  margin-top: 1rem;
}

.shareable-links__create-button {
  margin-left: 1rem;
}

.links__link {
  margin-bottom: 1.5625rem;
}

.links__link-header {
  display: flex;
  align-items: baseline;
}

.links__link-copy {
  margin-left: 0.5625rem;
  font-size: 0.8125rem;
  color: var(--color-brand);
  cursor: pointer;
}
