.error-boundary__message {
  max-width: 10rem;
}

.error-boundary__container {
  padding: 0.5rem;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
