.pipe-editor__modal .jsonpanel {
  height: 20rem;
}

.pipe-editor__action-bar {
  display: flex;
  align-items: center;
}

.pipe-editor__action-bar > button {
  margin-top: 1rem;
  margin-right: 0.5rem;
}
