.box {
  background-color: #f7f8f8;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: auto;
  height: 100%;
}

.box--noscroll {
  height: auto;
}

.box__header {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  flex-shrink: 0;
  height: 2.5rem;
}

.box__header-title {
  display: flex;
  overflow: hidden;
}

.box__header-actions {
  display: flex;
}

.box__header-title path {
  fill: black;
}

.box__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  background-color: #f7f8f8;
  height: 100%;
}

.box__content--padded {
  padding: 0.9375rem;
}

.box__content--padded-small {
  padding: 0.5rem;
}

.box__content--white {
  background-color: white;
}

.box__content .graph {
  width: 100%;
}

.box__title {
  background-color: #d6d6d6;
  padding: 0.625rem 1.25rem;
}
