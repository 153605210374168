.sesam-switch-parent {
  display: inline-flex;
  position: relative;
}

.sesam-switch-parent.with-margin {
  display: inline-flex;
  position: relative; 
  right: 25px;
}

.sesam-switch {
  display: inline-flex;
  position: relative;
  width: 40px;
  height: 22px;
  cursor: pointer;
  white-space: nowrap;
  bottom: 2px;
}

.sesam-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sesam-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sesam-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sesam-slider {
  background-color: #0381C1;
}

input:checked + .sesam-slider.lowlight {
  background-color: #bdbfc0;
}

input:focus + .sesam-slider {
  box-shadow: 0 0 1px #0381C1;
}

input:checked + .sesam-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.sesam-slider.round {
  border-radius: 34px;
}

.sesam-slider.round:before {
  border-radius: 50%;
}

.sesam-switch-left-label {
  position: relative;
  display: inline-block;
  height: 24px;
  right: 10px;
}

.sesam-switch-left-label.decoration {
  text-decoration: line-through;
}

.sesam-switch-right-label {
  position: relative;
  display: inline-block;
  height: 24px;
  left: 10px;
}

.sesam-switch-right-label.decoration {
  text-decoration: line-through;
}