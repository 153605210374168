.data-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.data-table__header,
.data-table__footer {
  display: flex;
  flex: 0 0 auto;
  padding: 0.25rem 0;
}

.data-table__title {
  margin: 0 3rem 0 0;
}

.data-table__header-controls,
.data-table__footer-controls {
  flex: 0 1 auto;
}

.data-table__col-selector {
  flex: 0 0 auto;
  margin: 0 0.25rem 0 auto;
}

.data-table__table {
  align-items: flex-start;
  border-collapse: collapse;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.data-table__table > thead,
.data-table__table > tfoot {
  display: block;
  flex: 0 0 auto;
  min-width: 100%;
}

.data-table__table > thead > tr > th {
  font-weight: normal;
  padding: 0;
}

.data-table__table > tbody > tr > td {
  padding: 0;
}

.data-table__table > tbody > tr:last-child {
  border-bottom-width: 0;
}

.data-table__table > tbody {
  display: block;
  flex: 1 1 auto;
  min-width: 100%;
  overflow: auto;
}

.data-table__table > thead > tr,
.data-table__table > tbody > tr {
  display: flex;
}

.data-table__footer {
  align-items: center;
  justify-content: space-between;
}

.data-table__selected-count {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

.data-table__cell-wrap {
  line-height: 2rem;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.data-table__cell-wrap > a {
  text-decoration: none;
}

td > .data-table__cell-wrap > a:hover {
  text-decoration: underline;
}

.data-table__col {
  display: block;
  overflow: hidden;
}

.data-table__col--default {
  flex: 1 1 12rem;
  text-align: left;
  width: 12rem;
}

.data-table__col--medium-text {
  flex: 4 1 12rem;
  text-align: left;
  width: 12rem;
}

.data-table__col--actions {
  flex: 1 1 8rem;
  font-size: 0.875rem;
  text-align: right;
  width: 8rem;
}

th.data-table__col--actions .data-table__cell-wrap {
  display: none;
}

.data-table__col--actions a,
.data-table__col--actions .btn {
  margin-right: 0.75rem;
}

.data-table__col--actions :last-child {
  margin-right: 0;
}

.data-table__col--short-text {
  flex: 1 1 5rem;
  text-align: left;
  width: 5rem;
}

.data-table__col--long-text {
  flex: 8 1 12rem;
  text-align: left;
  width: 12rem;
}

.data-table__col--date,
.data-table__col--number {
  flex: 1 0 9rem;
  text-align: right;
  width: 9rem;
}

.data-table__col--icon {
  flex: 0 0 2.5rem;
  text-align: center;
  width: 2.5rem;
}

.data-table__col--icon svg,
.data-table__col--icon .btn {
  vertical-align: middle;
}

.data-table__col--span-row {
  text-align: center;
  width: 100%;
}

.data-table__sort {
  background-color: transparent;
  border-width: 0;
  color: var(--color-brand);
  display: block;
  line-height: inherit;
  padding: 0;
  text-align: inherit;
  width: 100%;
}

.data-table__sort:hover,
.data-table__sort:focus {
  color: var(--color-brand-active);
  outline: none;
  text-decoration: underline;
}
