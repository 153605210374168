.button {
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: var(--font-family-base);
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.button:hover {
  text-decoration: none;
}

.button--active {
  background-color: #ccc !important;
}

.button__text {
  font-family: var(--font-family-base);
}