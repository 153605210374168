.permissions-editor .actions {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: right;
}

.permissions-editor .permission-tester {
  padding: 0 1rem;
}

.permissions-editor .inherited-setting {
  background-color: var(--color-background-semilight);
  margin-bottom: 3rem;
}

.permissions-editor .permissions-editor--dark .inherited-setting {
  background-color: var(--color-background-semilight--dark);
  margin-bottom: 3rem;
}

.permissions-editor .inherit-from {
  padding: 1rem 1rem 0 1rem;
}

.permissions-editor .acl-editor {
  background-color: #eaeaea;
}
