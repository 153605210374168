.alert .expansion-panel__summary-text::first-letter {
  text-transform: uppercase;
}

.alert__origin {
  padding: 0;
}

.alert__origin > li {
  display: inline;
}

.alert__origin > li:nth-child(n + 2)::before {
  content: ' — ';
}
