.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.legend-item__shape {
  margin-right: 0.625rem;
}

.legend-item__shape--line {
  height: 0.125rem;
  width: 1.25rem;
}

.legend-item__shape--block {
  width: 1.25rem;
  height: 0.625rem;
}
