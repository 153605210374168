.data-view {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.data-view__bar {
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: center;
}

.data-view__bar-controls {
  width: 70px;
  background-color: #f7f8f8;
  height: 100%;
  display: flex;
  border: 1px solid #d2d4d6;
  justify-content: space-around;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: 0;
}

.data-view__bar-controls__save,
.data-view__bar-controls__cancel {
  background: transparent;
  border: 0;
}

.data-view__bar-controls__cancel {
  color: var(--color-danger-highlight);
}

.data-view__bar-controls__save {
  color: '#008580';
}

.data-view__bar-formula {
  height: 2.625rem;
  width: 100%;
}

.data-view__bar-formula__input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.data-view__preview {
  display: flex;
  flex: 1;
  min-height: 0;
}

.data-view__preview-action {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
}

.data-view__preview-action__label {
  margin-right: 0.3125rem;
}

.data-view__steps {
  margin-left: 0.625rem;
  min-width: 7.9375rem;
}

.data-view__step {
  cursor: pointer;
  padding: 0.625rem;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-view__step--active {
  background-color: rgba(0, 161, 224, 0.33);
}

.data-view__step-delete {
  margin-left: 0.3125rem;
  color: var(--color-danger-highlight);
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-view__step-delete svg {
  width: 1rem;
  height: 1rem;
}

.data-view__step_add-step {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: var(--color-success);
  font-size: 23px;
  cursor: pointer;
}

.data-view__table {
  flex: 1;
  display: flex;
  overflow: auto;
}

.data-view__table--empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.data-view__wizard-modal {
  width: 60vw;
  max-width: 40rem;
  overflow: visible;
}
