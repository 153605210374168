.jsonpanel {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: auto;
}

.jsonpanel > div {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
}
