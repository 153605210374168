.menu {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-pronounced);
  margin: 0;
  max-width: 40vw;
  min-width: 10rem;
  padding: 0.5rem 0;
}

.menu__item {
  display: block;
}

.menu__action,
.menu__control {
  align-items: center;
  background: transparent;
  border: 0 solid transparent;
  display: block;
  font-family: var(--font-family-base);
  margin: 0 -1px;
  padding: 0.25rem 0.75rem;
  text-align: left;
  text-decoration: none;
  width: calc(100% + 2px);
}

.menu__action:not(:disabled):not(.menu__action--disabled) {
  cursor: pointer;
}

.menu__action:not(:disabled):not(.menu__action--disabled):hover {
  background-color: var(--color-brand);
  color: white !important;
}

.menu__action:not(:disabled):not(.menu__action--disabled):focus,
.menu__action:not(:disabled):not(.menu__action--disabled):focus-within {
  background-color: var(--color-brand-active);
  color: white !important;
  outline: none;
}

.menu__action > svg {
  height: 1rem;
  width: 1rem;
}

.menu__action--disabled {
  cursor: not-allowed;
}

.menu__separator {
  display: block;
  padding: 0.5rem 0;
}

.menu__separator > hr {
  background: var(--color-border);
  border: 0;
  height: 1px;
  margin: 0;
}

.menu-action-popup {
  max-width: 40rem;
  width: 50vw;
}
