.pipe-output {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex: 1 1 auto;
}

.pipe-output__download-entities {
  flex: 1;
}

.pipe-output__shareable-links {
  flex: 2;
}

.pipe-output__entites {
  flex: 1 1 auto;
}

.pipe-output__entity-viewer {
  flex: 1 1 auto;
}

.white-color {
  color: white;
}
