.editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.editor__options-btn {
  margin-left: 10px;
}

.editor__work-area {
  border: 1px solid var(--color-border);
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

/* @extends stretchy-panel */

.editor__panel > .head {
  font-size: 0.75rem;
  padding: 0.5rem;
}

.editor__panel--replacements {
  border-left: 1px solid var(--color-border);
  flex: 0 2 25%;
  max-width: 20rem;
  overflow: auto;
}

.editor__panel--extra {
  border-left: 1px solid var(--color-border);
  display: flex;
  flex: 0 1 40%;
  flex-direction: column;
  overflow: hidden;
}

.editor__panel--extra > .head {
  flex: 0 0 auto;
}

.editor__panel--extra > .body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.editor__panel--extra-large {
  flex: 0 1 55%;
}

.editor__panel--editor {
  flex-basis: 66.6%;
}

.editor__panel--schema {
  border-left: 1px solid var(--color-border);
  flex-basis: 33.3%;
  max-width: 20rem;
}

.editor__panel-error {
  display: flex;
  flex-direction: column;
}

.editor__panel-divider {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font-size: 0.75rem;
  justify-content: space-between;
  line-height: 1.75rem;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  white-space: nowrap;
}

.editor__panel-divider-title {
  flex: 0 2 auto;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor__panel-divider-tools {
  flex: 1 1 auto;
  overflow: hidden;
}

.editor__panel-divider-tools > .toolbar {
  margin: 0;
}

.editor__panel-top-bar {
  padding: 5px;
  display: flex;
}

.editor__panel-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}

.editor .configuration {
  display: flex;
  flex: 1 1 50%;
}

.editor .configuration > .stretchy-panel {
  flex-grow: 1;
  flex-shrink: 1;
}

.editor__work-area > .configuration .stretchy-panel > .body {
  /* HACK */
  display: flex;
  flex-direction: column;
}

.configuration__config-panels {
  display: flex;
}

.configuration__config-panel {
  flex: 1;
  padding: 5px;
  cursor: default;
}

.configuration__config-panels-gutter {
  width: 60px;
  margin-left: 6px;
  margin-right: 6px;
}

.editor .action-bar {
  flex: 0 0 auto;
  padding: 1rem 0.1rem 0 0.1rem;
}

.editor .action-bar__actions {
  justify-content: space-between;
}

.expand-button {
  position: absolute;
  top: 1px;
  right: 5px;
  cursor: pointer;
  font-size: 1.2rem !important;
}

.rotate-180 {
  transform: rotate(180deg);
}