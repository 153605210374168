.instructions-splash {
  width: 100%;
  margin-top: 50px;
  color: var(--color-instruction-header);
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructions-splash__title {
  font-size: 4em;
  margin: 0 0 40px 0;
}

.instructions-splash__content {
  margin: 0 0 40px 0;
  text-align: center;
  /** to make it work inside the data table cell */
  white-space: normal;
}

.instructions-splash__model {
  margin: auto;
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instructions-splash__component {
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.instructions-splash__arrow {
  margin: auto;
}

.instructions-splash__ok {
  color: #b4b4b4;
}
