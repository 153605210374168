.code-viewer {
  flex: 2 1 auto;
  line-height: 1.375;
  overflow: auto;
  padding: 0.5rem;
}

.code-viewer--wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code-viewer--scroll {
  /* hack for FF 58 */
  height: 1px;
}
