.panel-with-switch {
  display: flex;
  flex-direction: column;
}

.panel-with-switch .panel {
  display: none;
  margin-top: -1px;
  overflow: auto;
  flex-basis: 0;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 10;
}

.panel-with-switch.show .panel {
  display: block;
  flex-basis: auto;
  max-height: 500px;
}

.panel-with-switch .header {
  cursor: pointer;
  font-weight: 700;
  margin: 0;
  padding: 10px;
  position: relative;
}

/* .panel-with-switch .header::after {
  content: '▾';
  position: absolute;
  right: 1em;
  transform: translateY(-50%);
  transition: transform var(--duration-minor);
  top: 50%;
}

.panel-with-switch.show .header::after {
  transform: translateY(-50%) rotate(180deg);
} */
