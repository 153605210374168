.entity-viewer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.entity-viewer__content {
  flex: 1 1 auto;
  display: flex;
}

.entity-viewer__actions {
  display: flex;
  padding: 0.4375rem;
  border: 1px solid #dbdcde;
  justify-content: center;
}

.entity-viewer__action {
  font-size: 0.9rem;
}

.entity-viewer .jsonpanel {
  border: 1px solid #dbdcde;
}

.entity-viewer__page {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}
