.input-with-button {
  display: flex;
  width: 100%;
  height: 100%;
}

.input-with-button--inline {
  flex-direction: row;
  align-items: center;
}

.input-with-button--block {
  flex-direction: column;
}

.input-with-button__input {
  margin-right: 0.5rem;
  flex-grow: 1;
}
