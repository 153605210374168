.card-list-data-table {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.card-list-data-table__header {
  display: flex;
  justify-content: space-between;
  width: 415px;
}

.card-list-data-table__selector,
.card-list-data-table__show-all-subs {
  padding: 0.375rem 0;
}

.card-list-data-table__show-all-subs input {
  margin-left: 7px;
}

.button.MuiToggleButton-root.Mui-selected {
  background-color: #1f792f;
}
