.graph {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  position: relative;
}

.graph--dark {
  background-color: #2d333b;
}

.graph .diagram {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.graph .vis-network {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.graph .vis-network canvas {
  flex: 1 1 100%;
}

.graph .notice {
  flex: 0 0 auto;
}

.graph .update {
  background-color: white;
  padding: 10px;
  position: absolute;
  border: 1px solid var(--color-border);
  z-index: 2;
}

.graph .link {
  cursor: pointer;
  text-decoration: underline;
}

.graph .comparePanel .left {
  width: auto;
}

.graph__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
}
