/**
 * Dataset screen layout
 */

.dataset-inspector {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.dataset-inspector__filter {
  margin-bottom: 0.6875rem;
}

.dataset-inspector__browser {
  display: flex;
  flex: 1;
  overflow: auto;
}

/**
 * Post pop-up
 * HACK: This should be a type of popup, and the heading & action input should
 * not be changed by context.
 */
.postPopup {
  background: #fff;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-omni-subtle);
  display: flex;
  flex-direction: column;
  height: 95vh;
  left: 50%;
  outline: none;
  overflow: auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 95vw;
}
