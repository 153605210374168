/**
 * card
 * A simple container box
 */
.card {
  background-color: #fff;
  border: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  position: relative;
}

.card__header {
  padding: 1.5rem 1rem 1rem 1rem;
}

.card__text {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.card__header + .card__text {
  padding-top: 0;
  padding-left: 15px;
  padding-right: 20px;
}

.card__text:last-child {
  padding-bottom: 1.5rem;
}

.card__text > p:first-child {
  margin-top: 0;
}

.card__text > p:last-child {
  margin-bottom: 0;
}

.card__actions {
  border-top: 1px solid var(--color-border);
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-end;
  line-height: 1.75rem;
  margin-top: auto;
  padding: 0.5rem;
  align-items: center;
}

.card__actions:empty {
  display: none;
}

.card__actions a,
.card__actions .button {
  margin-right: 0.5rem;
}

.card__actions .button:hover {
  text-decoration: none;
}

.card__actions :last-child {
  margin-right: 0;
}

.card__actions-aside {
  margin-right: 0.75rem;
}

.card__badges {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}

.card__badges .badge {
  margin-left: 0.5rem;
}

.card__badges .badge:first-child {
  margin-left: 0;
}

/**
 * card--success
 * @extends card
 */
.card--success {
  border-color: var(--color-success);
}

.card--success .card__actions {
  background-color: var(--color-success-subtle);
  border-top-color: transparent;
}

/**
 * card--notification
 * @extends card
 */
.card--notification {
  border-color: var(--color-notification);
}

.card--notification .card__actions {
  background-color: var(--color-notification-subtle);
  border-top-color: transparent;
}

/**
 * Clickbox
 */
.card--clickbox__target:hover {
  text-decoration: underline;
  text-underline-position: auto;
}

.card--clickbox:hover {
  cursor: pointer;
}