/**
 * media
 * As used by great rock stars over the years
 * @see http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 */
.media {
  display: flex;
}

.media .media__bd {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media .media__bd > .heading-section {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media .media__img {
  align-self: flex-start;
  flex: 0 0 auto;
  margin-right: 1rem;
  overflow: hidden;
}

.media .media__img img,
.media .media__img svg {
  display: block;
}

/**
 * media--nowrap
 * Prevents lines in a media item from wrapping
 *
 * @mixin for .media
 */
.media--nowrap .media__bd {
  white-space: nowrap;
}

/**
 * media--avatar
 * An avatar with rounded border
 *
 * 1) IE 11 needs this; it doesn't maintain aspect ratio for SVGs
 *
 * @extends media
 */
.media--avatar {
  align-items: center;
}

.media--avatar .media__bd p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media--avatar .media__img img,
.media--avatar .media__img svg {
  height: 2.25rem; /* 1 */
  width: 2.25rem;
}

/**
 * media--icon
 * A media with a fixed-size icon
 *
 * @extends media
 */
.media--icon .media__img {
  align-self: center;
}

.media--icon .media__img img,
.media--icon .media__img svg {
  height: 2rem;
  width: 2rem;
}

.media--icon .media__bd {
  align-self: center;
}

/**
 * media--banner
 * A media for a notification banner
 *
 * @extends media
 */
.media--banner .media__img img,
.media--banner .media__img svg {
  height: 3rem;
  width: 3rem;
}

/**
 * media--stats
 * A big number with some text

 * @extends media
 */
.media--stats {
  align-items: center;
}

.media--stats .media__img {
  font-size: 10em;
  font-weight: bold;
  color: var(--color-brand);
}

.media--stats .media__bd {
  margin-left: 0.5em;
  font-size: 2em;
  max-width: 5em;
}
