.edit-panel {
  background-color: #fff;
  padding: 0.5rem;
}

.edit-panel > .toolbar {
  margin-bottom: 0;
}

.license {
  margin-bottom: 1.5rem;
}

.inactive-license {
  color: grey;
}
