.dataset-inspector-table__action {
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
}

.dataset-inspector-table__message {
  margin-bottom: 11px;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
}

.dataset-inspector-table__table {
  flex: 1;
  display: flex;
  overflow: auto;
}

.dataset-inspector-table__checkbox-group-label {
  margin-right: 0.3125rem;
}

.dataset-inspector-table__table-skeleton {
  mask-image: linear-gradient(180deg, black, transparent);
}
