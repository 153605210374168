.datasets-list-link {
  text-decoration: none;
}

.status-green {
  color: var(--color-status-green-light);
}

.status-red {
  color: var(--color-status-red-light);
}
